/**
 * 示例-自定义组件-通用布局容器
 * luxinwen
 * 2023-04
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">sp-row 通用布局容器</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <Form :label-width="100">
            <sp-row>
              <FormItem label="商品名称"><Input /></FormItem>
              <FormItem label="客户名称"><Input /></FormItem>
              <FormItem label="客户电话"><Input /></FormItem>
              <FormItem label="收货地址"><Input /></FormItem>
              <FormItem label="商品名称"><Input /></FormItem>
              <FormItem label="客户名称"><Input /></FormItem>
              <FormItem label="客户电话"><Input /></FormItem>
              <FormItem :label-width="0"><Button type="primary">查询</Button></FormItem>
            </sp-row>
          </Form>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;sp-row&gt;&lt;/sp-row&gt;</pre>
          <p>基本用法。直接往容器内插入 <span class="text-red">FormItem</span> 标签即可。</p>
        </Card>
        <Card class="page-main-content-item">
          <Form :label-width="100">
            <sp-row :opened="false">
              <FormItem label="商品名称"><Input /></FormItem>
              <FormItem label="客户名称"><Input /></FormItem>
              <FormItem label="客户电话"><Input /></FormItem>
              <FormItem label="收货地址"><Input /></FormItem>
              <FormItem label="商品名称"><Input /></FormItem>
              <FormItem label="客户名称"><Input /></FormItem>
              <FormItem label="客户电话"><Input /></FormItem>
              <FormItem :label-width="0"><Button type="primary">查询</Button></FormItem>
            </sp-row>
          </Form>
          <Divider orientation="left" plain>默认收起状态</Divider>
          <pre>&lt;sp-row :opened="false"&gt;&lt;/sp-row&gt;</pre>
          <p>设置属性 <span class="text-red">opened</span>，可以控制默认展开或收起状态。</p>
        </Card>
        <Card class="page-main-content-item">
          <Form :label-width="100">
            <sp-row :opened="false" :default-row="2">
              <FormItem label="商品名称"><Input /></FormItem>
              <FormItem label="客户名称"><Input /></FormItem>
              <FormItem label="客户电话"><Input /></FormItem>
              <FormItem label="收货地址"><Input /></FormItem>
              <FormItem label="商品名称"><Input /></FormItem>
              <FormItem label="客户名称"><Input /></FormItem>
              <FormItem label="客户电话"><Input /></FormItem>
              <FormItem :label-width="0"><Button type="primary">查询</Button></FormItem>
            </sp-row>
          </Form>
          <Divider orientation="left" plain>收起后展示2行</Divider>
          <pre>&lt;sp-row :default-row="2"&gt;&lt;/sp-row&gt;</pre>
          <p>设置属性 <span class="text-red">default-row</span>，可以控制收起后展示多行。</p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'col': {
            tips: '表单项列数，必须为24的整除倍数，如 1、2、3、4、6',
            type: 'Number',
            default: '3'
          },
          'expand': {
            tips: '是否使用展开收起',
            type: 'Boolean',
            default: 'true'
          },
          'opened': {
            tips: '是否默认全部展开',
            type: 'Boolean',
            default: 'true'
          },
          'defaultRow': {
            tips: '收起后展示行数',
            type: 'Number',
            default: '1'
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ]
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      }
    }
  };
</script>